.meet-founders-text {
    text-align: justify;
}

.rehumanizing-network-image {
    border-radius: 50%;
    height: 200px;
    width: auto;
    object-fit: scale-down;
}

.rehumanizing-network-title {
    display: flex;
    justify-content: center;
}

.meet-founders-second-row-mobile {
    flex-direction: column-reverse;
}

@media (min-width: 768px) 
{
    .meet-founders-text-first {
        text-align: left;
        margin-right: 20%
    }
    .meet-founders-text-second {
        text-align: left;
        margin-left: 15%;
    }

    .meet-founders-text-title {
        text-align: center;
    }

    .meet-founders-second-row-mobile {
        flex-direction: row;
    }
}