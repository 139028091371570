.video-component-image {
    width: 100%;
    min-height: 300px;
    object-fit: cover;
}

.video-component-image-position {
    text-align: center;   
}

.video-component-co-founders-wrapper {
    display: flex;
    justify-content: center;
}

.video-component-styled-text {
    text-align: justify;
}

.video-component-text-position {
    text-align: center;  
}

.video-component-text-title {
    text-align: left;
}

@media (min-width: 768px) 
{
    .video-component-image {
        min-height: auto;
        max-width: 1280px;
    }

    .video-component-text-title {
        text-align: center;
    }

    .video-component-styled-text {
        text-align: center;
    }
    .video-component-image-position {
        text-align: right;   
    }
    .video-component-text-position {
        text-align: left;  
    }
}