.contact-us-title-image {
    max-height: 180px;
    width: 100%;
    object-fit: cover;
    padding: 0;
}

.contact-us-centered-text {
    text-align: justify;
}

.contact-us-margins {
    margin-left: 5px;
    margin-right: 5px;
}

@media (min-width: 768px) 
{
    .contact-us-title-image {
        max-height: 280px;
        object-position: 100% 45%;
    }

    .contact-us-centered-text {
        margin-left: 15%;
        margin-right: 15%;
        text-align: center;
    }
}
