.our-team-image {
    width: 300px;
    height: auto;
}

.our-team-image-position {
    text-align: center;   
}

.our-team-co-founders-wrapper {
    display: flex;
    justify-content: center;
}

.our-team-styled-text {
    text-align: justify;
}

.our-team-text-position {
    text-align: center;  
}

@media (min-width: 768px) 
{
    .our-team-styled-text {
        text-align: center;
    }
    .our-team-image-position {
        text-align: right;   
    }
    .our-team-text-position {
        text-align: left;  
    }
    .our-team-image {
        width: 250px;
        height: auto;
    }
}