.ten-reasons-cold-reach-page-styled-text {
    text-align: left;
}

.ten-reasons-cold-reach-page-title-image {
    max-height: 180px;
    width: 100%;
    object-fit: cover;
    padding: 0;
}

.ten-reasons-cold-reach-page-title {
    display: flex;
    justify-content: center;
}

@media (min-width: 768px) 
{
    .ten-reasons-cold-reach-page-styled-text {
        max-width: 80%;
        text-align: center;
    }

    .ten-reasons-cold-reach-page-title-image {
        max-height: 300px;
        object-position: 100% 25%;
    }
}