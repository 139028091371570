.person-details-component-wrapper {
    border-radius: 0px 0px 8px 8px;
    background-color: #F5F5F580;
    padding-bottom: 10px;
}

.person-details-component-title {
    border-radius: 8px 8px 0px 0px;
    background-color: #DBDBDB;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 5px;
}

.person-details-component-name-text {
    color: #2A2928;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 500;
}