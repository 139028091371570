.mission-wrapper {
    background-color: #FFC3001F;
    padding-bottom: 30px;
}

.mission-picture {
    width: 100%;
    height: 100%; 
    object-fit: cover;
    object-position: center; 
}
.mission-styled-text {
    text-align: justify;
}

@media (min-width: 768px) 
{
    .mission-styled-text {
        text-align: left;
        margin-left: 0;
        margin-right: 0;
    }
}