
.terms-conditions {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    color: #333; 
    background-color: #fff;
    border-radius: 8px; 
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); 
    margin-bottom: 20px;
    margin-top: 20px;
}