.pricing-main-wrapper {
    height: "calc(100vh - 100px)"; 
    max-width: '90%';
}

.pricing-components-wrapper {
    margin-left: 20px;
    margin-right: 20px;
}


@media (min-width: 768px) 
{
    .pricing-single-component {
        margin-left: 20%;
        margin-right: 20%;
    }
}