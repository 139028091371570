.summer-slowdown-wrapper{
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: justify;
}

.summer-slowdown-image {
    width: auto;
    height: 250px;
    object-fit: cover;
    margin-bottom: 30px;
}

.summer-slowdown-image-wrapper {
    text-align: right;
    align-items: center;
}

.summer-slowdown-reasons {
    display: flex;
    justify-content: space-evenly;
}

.summer-slowdown-mobile-text {
    text-align: center;
}

@media (min-width: 768px) 
{
    .summer-slowdown-wrapper{
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 20px;
        padding-top: 20px;
        text-align: left;
    }

    .summer-slowdown-image {
        height: 350px;
        margin-bottom: 0;
    }

    .summer-slowdown-mobile-direction {
        flex-direction: row;
    }
}