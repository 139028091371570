.custom-label-align-left {
    text-align: left;
  }
  
  .custom-label-align-right {
    text-align: right;
  }
  
  .custom-label-align-center {
    text-align: center;
  }
  
  .custom-label-bold {
    font-weight: 600 !important;
    font-size: 16px !important;
  }

.custom-label {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
}

.custom-label-centered {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #666666;
}

.custom-label-color-green {
  background-color: #33C38F1F;
  color: #33C38F;
  padding: 10px;
  border-radius: 8px;
}