.meeting-counter {
    border-radius: 8px;
    border: 1px solid #DBDBDB;
}

.meeting-counter-number {
    color: #FF8A5C;
    font-family: 'Arimo';
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}