.custom-styled-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    transition: background-color 0.2s;
    box-shadow: -8px -8px 16px 0px #FFFFFF,
                8px 8px 16px 0px #C9D9E8;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    width: 100%; 
    height: 100%;

  }
  
  .custom-styled-button.primary {
    background-color: white;
    color: #2A2928;
  }
  
  .custom-styled-button.primary:hover {
    background-color: #F34804;
    color: #FFFFFF;
  }
  
  .custom-styled-button.secondary {
    background-color: #666666;
    font-weight: 400;
    color: white;
  }
  
  .custom-styled-button.secondary:hover {
    background-color: #545b62;
    font-weight: 400;
    color: white;
  }
  
  .custom-styled-button.third {
    background-color: #F34804;
    color: #FFFFFF;
  }
  
  .custom-styled-button.third:hover {
    background-color: white;
    color: #2A2928;
  }
  
  .custom-styled-button.danger {
    background-color: #dc3545;
    color: white;
  }
  
  .custom-styled-button.danger:hover {
    background-color: #c82333;
  }
  
  .custom-styled-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

