.contact-styled-text {
    text-align: justify;
}

.contact-link {
    color: #666666;
}

@media (min-width: 768px) 
{
    .contact-styled-text {
        text-align: center;
    }
}