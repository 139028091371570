.styled-video-wrapper {
    text-align: center;
    margin: 20px auto;

}

.styled-video-player {
    max-width: 100%;
    height: auto;
    border-radius: 50px 50px 50px 50px;
}
