.full-height {
    min-height: 100vh;
}

.login-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.signup-image {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    object-position: 100% 45%;
}

@media (min-width: 860px) 
{
    .login-image {
        width: 100%;
        min-height: 100vh;
        object-fit: cover;
    }

    .login-component {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .full-height{
        height: calc(100vh - 100px)
    }

    .signup-image { 
        width: 100%;
        min-height: 100vh;
        max-height: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}