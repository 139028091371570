.about-us-title-image {
    max-height: 180px;
    width: 100%;
    object-fit: cover;
    padding: 0;
}

.about-us-centered-text {
    text-align: justify;
}

.about-us-margins {
    margin-left: 5px;
    margin-right: 5px;
}

@media (min-width: 768px) 
{
    .about-us-title-image {
        max-height: 420px;
        object-position: 100% 35%;
    }

    .about-us-centered-text {
        margin-left: 15%;
        margin-right: 15%;
        text-align: center;
    }
    .make-meeting-center {
        height: 560px;
    } 
}
