.power-trusted-introductions-styled-text {
    text-align: left;
}

.power-trusted-introductions-title-image {
    width: auto;
    height: 250px;
    object-fit: cover;
    margin-bottom: 30px;
}

.power-trusted-introductions-title {
    display: flex;
    justify-content: center;
}

@media (min-width: 768px) 
{
    .power-trusted-introductions-styled-text {
        max-width: 80%;
        text-align: center;
    }
}