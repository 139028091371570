
.make-a-meeting-wrapper {
    background-color: #F5F5F580;
    padding-bottom: 20px;
}

.make-meeting-center {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    height: 100%; 
}


@media (min-width: 768px) 
{
    .make-meeting-center {
        height: 560px;
    } 
}
