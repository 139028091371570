.chat-wrapper {
    border-radius: 8px;
    background: var(--Light-BG, rgba(245, 245, 245, 0.50));
    padding: 20px;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chat-messages-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.chat-container {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: auto;
}

.chat-title {
    border-radius: 8px 8px 0px 0px;
    background-color: #DBDBDB;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.chat-message-wrapper-sender {
    border-radius: 5px;
    background: var(--Primary-White, #FFF);
    padding-left: 10px;
    padding-top: 10px;
    margin-top: 5px;
    width: 90%;
    align-self: flex-start;
}

.chat-message-wrapper-receiver {
    border-radius: 5px;
    background: #FEE9E1;
    padding-left: 10px;
    padding-top: 10px;
    margin-top: 5px;
    width: 90%;
    align-self: flex-end;
}

.chat-message-wrapper-date-sender {
    padding: 10px;
    width: 90%;
    align-self: flex-start;
    font-size: 12px;
}

.chat-message-wrapper-date-receiver {
    padding: 10px;
    width: 90%;
    align-self: flex-end;
    font-size: 12px;
}

.chat-message-wrapper-introductable-team {
    border-radius: 5px;
    background: #E7F8F2;
    padding-left: 10px;
    padding-top: 10px;
    margin-top: 5px;
    width: 90%;
    align-self: flex-start;
}

.chat-messages {
    height: 300px;
    margin-bottom: 8px;
    padding: 10px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
}

.message-input {
    padding: 10px;
    margin-bottom: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}