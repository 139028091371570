.single-image-collage-wrapper {
    height: 300px;
    max-width: fit-content;
    justify-items: center;
    display: flex;
}

.single-image-collage-image {
    border-radius: 24px;
    object-fit: cover;
    z-index: 100;
    height: 220px;
    width: 180px;
    transform: translateY(40px);
}

.single-image-collage-yellow-rectangle {
    width: 80px;
    height: 200px;
    border-radius: 56px 16px 16px 16px;
    background-color: #FFF8E0;
    transform: translateX(50px);
}

.single-image-collage-green-rectangle {
    border-radius: 23px 23px 92px 23px;
    height: 220px;
    width: 60px;
    background-color: #E7F8F2;
    transform: translateY(80px) translateX(-20px);
}


@media (min-width: 768px) 
{
    .single-image-collage-wrapper {
        height: 440px;
        max-width: fit-content;
        justify-items: center;
        display: flex;
    }

    .single-image-collage-image {
        height: 330px;
        width: 270px;
        transform: translateY(50px) translateX(-80px);
    }    

    .single-image-collage-yellow-rectangle { 
        width: 179px;
        height: 299px;
        transform: translateX(50px);
    }

    .single-image-collage-green-rectangle {
        width: 89px;
        height: 276px;
        transform: translateY(150px) translateX(-120px);
    }
}