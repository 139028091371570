.great-day-to-leave-me-alone-wrapper{
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: justify;
}

.great-day-to-leave-me-alone-image {
    width: auto;
    height: 250px;
    object-fit: cover;
    margin-bottom: 30px;
}

.great-day-to-leave-me-alone-image-wrapper {
    text-align: right;
    align-items: center;
}

.great-day-to-leave-me-alone-rectangle {
    padding-left: 20px;
    padding-top: 20px;
    border-radius: 24px 24px 96px 24px;
    height: 150px;
    margin-top: 25px;
    text-align: left;
}

.great-day-to-leave-me-alone-reasons {
    display: flex;
    justify-content: space-evenly;
}

.great-day-to-leave-me-alone-mobile-text {
    text-align: center;
}

@media (min-width: 768px) 
{
    .great-day-to-leave-me-alone-wrapper{
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 20px;
        padding-top: 20px;
        text-align: left;
    }

    .great-day-to-leave-me-alone-image {
        height: 350px;
        margin-bottom: 0;
    }

    .great-day-to-leave-me-alone-rectangle {
        width: 350px;
        height: 150px;
        margin-top: 0;
    }

    .great-day-to-leave-me-alone-mobile-direction {
        flex-direction: row;
    }
}