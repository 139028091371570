.custom-link-align-left {
  text-align: left;
}

.custom-link-align-right {
  text-align: right;
}

.custom-link-bold {
  font-weight: 600 !important;
  font-size: 16px !important;
}

.custom-link {
    color: #F34804; 
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none; 
  }
  
.custom-link:hover, .custom-link:focus {
    color: #F34804; 
    text-decoration: underline;
}
