.custom-input {
    font-family: Jost;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    padding: 12px 16px 12px 16px;
    border-radius: 8px;
    border: 1px solid #ADACB2;
    height: 56px;
}
  
.custom-input:focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 72, 4, 0.25);
}
