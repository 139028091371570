.navbar-wrapper {
    width: 100%;
    color: #F34804;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.navbar-item {
    border-color: #FFFFFF;
    color: #666666;
    text-decoration: none;
    font-family: 'Raleway';
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

.navbar-item:hover {
    color: #666666;
    font-weight: 700;
}

.profile-settings {
    background-color: #e2dede60;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 18px;
}

.menu-logo-image {
    max-width: 140px;
    height: auto;
}

@media (max-width: 400px) {
    .menu-logo-image {
        max-width: 120px;
    }
}

@media (min-width: 768px) {
    .menu-logo-image {
        max-width: 280px;
        height: auto;
    }
    .mobile-menu-icon {
        display: none;
    }

    .navbar-item {
        margin-right: 10px;
    }

    .navbar-wrapper {
        padding-left: 8%;
        padding-right: 8%;
    }

    .profile-settings { 
        padding: 20px;
    }
}

@media (max-width: 767px) {
    .navbar-item {
        display: none;
    }
    .mobile-menu-icon {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        cursor: pointer;
        font-size: 24px;
    }
    .mobile-menu {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        z-index: 20;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .mobile-menu .navbar-item {
        display: block;
        margin: 20px 0;
        font-size: 20px;
    }
    .close-menu {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 24px;
        cursor: pointer;
    }
}
