.profile-component {
    max-width: 500px;
    margin: auto;
}

.profile-info {
    margin-bottom: 20px;
}

.form-label {
    margin-top: 20px;
    font-weight: 600;
}

.profile-form .form-group {
    margin-bottom: 20px;
}

.header {
    text-align: center;
    margin: 30px;
}

.my-profile-mobile-columns {
    display: flex;
    flex-direction: column;
}

.my-profile-input-element {
    margin-top: 10px;
}

@media (min-width: 860px) 
{
    .my-profile-mobile-columns {
        flex-direction: row;
    }

    .my-profile-input-element {
        margin-top: 0;
    }
}