.cultivating-marketing-success-wrapper{
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: justify;
}

.cultivating-marketing-success-image {
    width: auto;
    height: 250px;
    object-fit: cover;
    margin-bottom: 30px;
}

.cultivating-marketing-success-image-wrapper {
    text-align: right;
    align-items: center;
}

.cultivating-marketing-success-reasons {
    display: flex;
    justify-content: space-evenly;
}

.cultivating-marketing-success-mobile-text {
    text-align: center;
}

@media (min-width: 768px) 
{
    .cultivating-marketing-success-wrapper{
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 20px;
        padding-top: 20px;
        text-align: left;
    }

    .cultivating-marketing-success-image {
        height: 350px;
        margin-bottom: 0;
    }

    .cultivating-marketing-success-mobile-direction {
        flex-direction: row;
    }
}