.funnel-vision-wrapper{
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: justify;
}

.funnel-vision-image-wrapper {
    text-align: right;
    align-items: center;
}
.funnel-vision-reasons {
    display: flex;
    justify-content: space-evenly;
}

.funnel-vision-mobile-text {
    text-align: center;
}

@media (min-width: 768px) 
{
    .funnel-vision-wrapper{
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 20px;
        padding-top: 20px;
        text-align: left;
    }

    .funnel-vision-mobile-direction {
        flex-direction: row;
    }
}