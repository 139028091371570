.landing-page-content{
    z-index: 2;
}

.top-banner {
    display: none;
}

.top-banner-mobile {
    display: flex;
    padding-top: 80px;
    margin-top: -60px;
    z-index: 0; 
    padding-bottom: 40px;
}

.banner-image {
    display: flex;
    align-items: center;
    justify-content: center;
    --bs-gutter-x: 0;
}

.banner-image-left {
    max-width: 100%;
}

.banner-image-right {
    max-width: 100%;
    object-fit: contain; 
    margin-left: auto;
}

.banner-title {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
}

.people-connections-image {
    object-fit: scale-down;
    height: 300px;
    width: 100%;
}

.landin-page-main-title {
    text-align: center;
}

.landing-page-gradient { 
    background: linear-gradient(45deg, rgba(243, 72, 4, 0.5), rgba(51, 195, 143, 0.3)), 
    linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)); 
    background-blend-mode: overlay;
    z-index: 1;
}

.landing-page-gradient-with-top { 
    background: 
        linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 20%), 
        linear-gradient(45deg, rgba(51, 195, 143, 0.3), rgba(243, 72, 4, 0.25) ), 
        linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 20%); 
    background-blend-mode: overlay, overlay, overlay; 
}

@media (min-width: 760px) 
{
    .people-connections-image {
        object-fit: scale-down;
        height: 500px;
        width: 100%;
    }

    .landin-page-main-title {
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
    }

    .top-banner-mobile {
        display: none;
    }

    .banner-image-left {
        min-height: 100%; 
        object-fit: contain;
        margin-right: auto;
    }
    
    .banner-image-right {
        min-height: 100%; 
        object-fit: contain; 
        margin-left: auto;
    }
    
    .top-banner {
        display: flex;
        min-height: 800px;
        padding-top: 80px;
        margin-top: -60px;
        z-index: -1; 
    }

    .banner-image {
        min-height: 500px;
    }
}