.confession-ceo-who-lurks-styled-text {
    text-align: justify;
}

.confession-ceo-who-lurks-image {
    border-radius: 50%;
    height: 200px;
    width: auto;
    object-fit: scale-down;
}

.confession-ceo-who-lurks-title {
    display: flex;
    justify-content: center;
}

@media (min-width: 768px) 
{
    .confession-ceo-who-lurks-styled-text {
        padding-left: 20%;
        padding-right: 20%;
        text-align: left;
    }
}