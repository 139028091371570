
.form-check-input:checked {
    background-color: #F34804 !important;
    border-color: #F34804 !important;
}

.form-check-input:focus {
    border-color: #F34804 !important;
    outline: 0;
    box-shadow: none !important;
}