.custom-calendar-wrapper {
    padding: 12px 16px 12px 16px;
    background-color: #fff;
    border: 1px solid #ADACB2;
    width: 100%;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-position: right 0.75rem center; 
    background-size: 1rem 1rem;
}

.custom-calendar-input {
    border: none; 
    width: 100%;
    box-sizing: border-box;
    color:#666666;
    font-family: 'Jost';
    font-weight: 400;
    font-size: 16px;
}
