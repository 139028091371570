.falling-new-business-styled-text {
    text-align: left;
}

.falling-new-business-title-image {
    width: auto;
    height: 250px;
    object-fit: cover;
    margin-bottom: 30px;
}

.falling-new-business-title {
    display: flex;
    justify-content: center;
}

@media (min-width: 768px) 
{
    .falling-new-business-styled-text {
        max-width: 80%;
        text-align: center;
    }
}