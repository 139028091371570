.not-found-wrapper {
    text-align: center;
    margin-top: 25vh;
    margin-bottom: 35vh;
}

@media (min-width: 768px) 
{
    .not-found-title {
        padding-left: 15%;
        padding-right: 15%;
    }
    
    .not-found-text {
        padding-left: 25%;
        padding-right: 25%;
    }
}