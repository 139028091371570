.power-introductions-component-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    position: relative;
    min-height: 550px;

}

.power-introductions-component-logo-circled {
    width: 120px;
    height: auto; 
    position: absolute; 
    left: 50px; 
    top: 30px;
}

.power-introductions-component-rectangle {
    border-radius: 92px 23px 23px 23px;
    padding-top: 40px;
    padding-left: 30px;
    padding-bottom: 40px;
    background: var(--Gradient, linear-gradient(110deg, #FF8A5C 0%, #F34804 100%));
    width: 90%;
    position: absolute; 
    top: 180px;
}

.power-introductions-component-person-image {
    border-radius: 92px 23px 23px 23px;
    width: 170px;
    height: 250px;
    object-fit: cover;
    object-position: 100% 35%;
    z-index: 5;
    position: absolute; 
    right: 30px;
    top: 0;
}

@media (min-width: 768px) {
    .power-introductions-component-wrapper {
        min-height: 650px;
    }

    .power-introductions-component-logo-circled {
        width: 150px;
        left: 30%; 
        top: 0;
    }

    .power-introductions-component-rectangle {
        padding-top: 40px;
        padding-left: 60px;
        padding-bottom: 40px;
        padding-right: 180px;
        width: 600px;
        left: 10%; 
        top: 30%;
    }

    .power-introductions-component-person-image {
        border-radius: 92px 23px 23px 23px;
        width: 300px;
        height: 400px;
        left: 60%; 
        top: 0;
    }
}

@media (min-width: 980px) {

    .power-introductions-component-rectangle {
        left: 15%; 
    }

    .power-introductions-component-person-image {
        left: 60%; 
        width: 300px;
    }
}

@media (min-width: 1200px) {

    .power-introductions-component-rectangle {
        left: 20%; 
        width: 600px;
    }

    .power-introductions-component-person-image {
        left: 55%; 
        width: 400px;
    }
}

@media (min-width: 1580px) {
    .power-introductions-component-person-image {
        left: 45%; 
        top: 0;
        width: 600px;
    }
}
