.pricing-component-wrapper {
    background-color:rgba(219, 219, 219, 0.5);
    border-radius: 56px 16px 16px 16px;
    height: 100%;        
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    padding-bottom: 20px;
}

.pricing-component-icon-wrapper {
    border-radius: 30px 6px 6px 6px;
    background-color:rgba(243, 72, 4, 0.1);
    opacity: 0.1px;
    color: #F34804;
    font-size: 30px;
}

.price-styling {
    font-family: 'Arimo';
    font-size: 40px;
    font-weight: 500;
    text-align: left;
    color: #F34804;
}

.small-price-styling {
    font-family: 'Raleway';
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    color: #F34804;
}

.benefits-text {
    font-family: 'Raleway';
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #666666;
}