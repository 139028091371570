.news-page-title-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    height: 330px;
    border-radius: 0px 0px 120px 120px;
    background: #F5F5F580;
}

.news-page-items-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.news-page-items-wrapper-title {
    justify-self: flex-start;
    text-align: left;
}

@media (min-width: 768px) 
{
    
}