.custom-checkbox-wrapper {
    display: flex;
    align-items: center;
  }
  
.custom-checkbox-wrapper input[type="checkbox"] {
    margin-right: 8px; 
    width: 15.83px;
    height: 15.83px;
    top: 2.08px;
    left: 2.08px;
    border: 1.5px 0px 0px 0px;
}

.custom-checkbox-wrapper label {
    margin-bottom: 0; 
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
}

.custom-checkbox-wrapper input:focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 72, 4, 0.25);
}

.custom-checkbox-wrapper input:checked {
    background-color: #F34804;
    border-color: #F34804;
}