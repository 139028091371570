.horizontal-line-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

}

.horizontal-line-label { 
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    color: #666666;
}

.horizontal-line {
    border: 1px solid #DBDBDB;
    height: 1px;
    width: 100%;
}

.horizontal-line-light {
    border: 1px solid #F5F5F580;
    height: 1px;
    width: 100%;
}
