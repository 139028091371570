.connection-page-mobile-text {
    text-align: left;
}

.connection-page-input-row {
    padding-top: 20px;
    max-width: 500px;
}

@media (min-width: 768px) 
{
    .connection-page-mobile-text {
        text-align: center;
    }
}