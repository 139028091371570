.faq-styled-text {
    text-align: justify;
}

.faq-styled-text-title {
    text-align: left;
}

@media (min-width: 768px) 
{
    .faq-styled-text-title {
        text-align: left;
    }

    .faq-styled-text {
        max-width: 80%;
    }
}