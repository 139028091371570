.process-steps-wrapper {
    margin-left: 20px;
    margin-right: 20px;
}

@media (min-width: 768px) 
{
    .process-steps-wrapper {
        display: flex;
        justify-content: center;
    }
}