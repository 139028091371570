.our-values-wrapper {
    width: 55%;
}

.value-col {
    min-height: 200px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.value-circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    transform: translateY(30px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 30px;
}

.value-circle-yellow {
    background-color: #FFC300;
}

.value-circle-salmon {
    background-color: #FF8A5C;
}

.value-circle-green {
    background-color: #33C38F;
}

.values-rectangle {
    padding: 20px;
    border-radius: 40px 16px 16px 16px;
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.values-green-rectangle {
    background-color: #E7F8F2; 
}

.values-grey-rectangle {
    background-color: #DBDBDB; 
}

.values-yellow-rectangle {
    background-color: #FFF8E0; 
}

@media (min-width: 768px) 
{
}
