.basic-user-page-container {
    width: 80%;
    margin: auto;
    margin-top: 20px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .basic-user-title {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .form-section {
    margin-bottom: 30px;
  }
  
  .form-label {
    font-size: 16px;
    color: #555;
    margin-bottom: 5px;
    display: block;
  }
  
  .linkedIn-section, .last-company-section {
    border-top: 1px solid #eee;
    padding-top: 20px;
  }
  
  .optional-section-title {
    font-size: 18px;
    margin-top: 20px;
    color: #666;
  }
  
.success-new-user-container {
    max-width: 600px;
    margin: auto;
    padding: 40px;
    text-align: center;
}

.success-new-user-header {
    font-size: 24px;
    color: #F34804;
    margin-bottom: 20px;
}

.success-new-user-text {
    font-size: 16px;
    margin: 20px 0;
    line-height: 1.5;
    color: #303030;
}

.profile-image, .basic-profile-image {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  object-position: center;
}

@media (min-width: 760px) 
{
  .profile-image { 
      width: 100%;
      min-height: 100vh;
      max-height: 100%;
      object-fit: cover;
      object-position: 60% 75%;
  }
  .basic-profile-image { 
      width: 100%;
      min-height: 100vh;
      object-fit: cover;
      object-position: 80% 75%;
  }
}