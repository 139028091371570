.requestor-role-title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    flex-direction: column;
    height: 330px;
    padding-left: 10%;
    padding-right: 10%;
    border-radius: 0px 0px 120px 120px;
    background: #F5F5F580;
}

.requestor-role-wrapper{
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: justify;
}


.requestor-role-image {
    width: auto;
    height: 250px;
    object-fit: cover;
    margin-bottom: 30px;
}

.requestor-role-image-wrapper {
    text-align: right;
    align-items: center;
}

.requestor-role-rectangle {
    padding-left: 20px;
    padding-top: 20px;
    border-radius: 24px 24px 96px 24px;
    min-height: 150px;
    margin-top: 25px;
    text-align: left;
}

.requestor-role-reasons {
    display: flex;
    justify-content: space-evenly;
}

.requestor-role-mobile-text {
    text-align: left;
}

@media (min-width: 768px) 
{
    .requestor-role-wrapper{
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 20px;
        padding-top: 20px;
        text-align: center;
    }

    .requestor-role-title-wrapper {
        text-align: center;
    }

    .requestor-role-image {
        height: 350px;
        margin-bottom: 0;
    }

    .requestor-role-rectangle {
        width: 250px;
        min-height: 220px;
        margin-top: 0;
    }

    .requestor-role-mobile-direction {
        flex-direction: row;
    }
}

@media (min-width: 1424px) 
{
    .requestor-role-rectangle {
        width: 350px;
        min-height: 220px;
    }
}