.warm-introductions-styled-text {
    text-align: justify;
}

.warm-introductions-wrapper{
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: justify;
}

.warm-introductions-title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    height: 330px;
    border-radius: 0px 0px 120px 120px;
    background: #F5F5F580;
}

.warm-introductions-image {
    max-height: 250px;
    width: auto;
}

.warm-introductions-image-wrapper {
    text-align: right;
}

.warm-introductions-rectangle {
    border-radius: 16px 16px 16px 40px;
    width: auto;
    height: 220px;
}

.warm-introductions-mobile-direction {
    flex-direction: column-reverse;
}

@media (min-width: 768px) 
{
    .warm-introductions-wrapper{
        padding-left: 20%;
        padding-right: 20%;
        padding-bottom: 20px;
        padding-top: 20px;
        text-align: justify;
    }

    .warm-introductions-image {
        max-height: 300px;
        width: auto;
    }

    .warm-introductions-rectangle {
        height: 250px;
    }

    .warm-introductions-mobile-direction {
        flex-direction: column;
    }
}