.meetings-table {
    margin-left: 10%;
    margin-right: 10%;
}

.filter-checkboxes {
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 20px;
    margin-top: 50px;
}

.meeting-page-description-wrapper {
    border-radius: 0px 0px 8px 8px;
    background-color: #F5F5F580;
    padding-bottom: 10px;
}
.meeting-page-description-title {
    border-radius: 8px 8px 0px 0px;
    background-color: #DBDBDB;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
}

.meeting-page-status-text {
    color: #666666;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
}