.search-bar-container {
    border-radius: 8px;
    background: linear-gradient(90.56deg, #FF9C97 16.94%, #FFBA7F 100.96%);
}

.search-bar-button{
    margin-top: 20px;
}

@media (min-width: 768px) 
{
    .search-bar-button {
        padding-right: 20px;
        padding-left: 20px;
        margin-top: 0;
        max-width: 100%;
    }
}