.footer-wrapper {
    width: 100%;
    background-color: #2A2928;
    color: #FFFFFF;
    height: 60px;
    
}

.link-footer, .link-footer:hover, .link-footer:focus {
    color: #FFFFFF;
    text-decoration: none;  
}

.social-icons {
    margin: 0 10px;
    color: #FFFFFF;
    font-size: 15px;
}

.footer-elements-position {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    text-align: center;
}

.footer-elements-second {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
}

@media (min-width: 1120px) 
{
    .footer-wrapper {
        height: 40px;
    }

    .footer-elements-position {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: auto;
        margin-left: 20px;
        margin-right: 20px;
    }

    .footer-elements-second {
        justify-content: end;
    }
}
