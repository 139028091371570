.upload-file-wrapper {
    display: flex;
    align-items: center;
    justify-content: center; 
}

.dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 300px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-image: url('../../../content/account/upload_file.svg'); 
}

.dropzone p {
    margin: 0;
    font-size: 16px;
    color: #333333;
}
