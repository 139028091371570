.ai-introductable-title-image {
    max-height: 180px;
    width: 100%;
    object-fit: cover;
    padding: 0;
}

.ai-introductable-text {
    text-align: justify;
}

.ai-introductable-green-wrapper {
    background-color: #E7F8F2;
    border-radius: 24px 24px 96px 24px;    
    padding-left: 20px;
    padding-bottom: 20px;
    max-width: 300px;
}

.ai-introductable-yellow-wrapper {
    background-color: #FFF8E0;
    border-radius: 24px 24px 96px 24px;    
    padding-left: 20px;
    padding-bottom: 20px;
    max-width: 300px;
}

.ai-introductable-reasons {
    justify-content: space-evenly;
}

@media (min-width: 768px) 
{
    .ai-introductable-title-image {
        max-height: 260px;
        object-position: 100% 25%;
    }

    .ai-introductable-text {
        text-align: center;
    }

    .ai-introductable-green-wrapper {
        max-width: 500px;
    }
    
    .ai-introductable-yellow-wrapper {
        max-width: 500px;
    }
}
