.styled-table-wrapper {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #DBDBDB;
}

.styled-table-header {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #666666;
}

.cell-background-green {
    border-radius: 16px;
    background-color: #a2fbdc;
}
.cell-background-yellow {
    border-radius: 16px;
    background-color: #fee284;
}
.cell-background-red {
    border-radius: 16px;
    background-color: #FFCCCB;
}

.styled-table-row{
    display: flex;
    align-items: center;
    padding-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.styled-table-row-selected {
    background-color: #FEE9E1;
}

.styled-table-row:hover {
    background-color: #FEE9E1;
}