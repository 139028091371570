.footer-addition-wrapper {
    width: 100%;
    background-color: #2A2928;
    color: #FFFFFF;
    min-height: 420px;
    padding-top: 20px;
    margin-top: 20px;
}

.footer-addition-elements-position {
    display: flex;
    justify-content: space-around;
    align-items: top;
    height: 100%;
    width: 100%;
    text-align: center;
    margin-bottom: 16px;
}

.footer-additon-logo-image {
    max-width: 200px;
    height: auto;
}

.footer-addition-column-header {
    opacity: 60%;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #FFFFFF;
}

.footer-addition-column-link {
    font-family: 'Raleway';
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #FFFFFF;
    text-decoration: none;  
}

.footer-mobile-menu {
    padding-top: 16px;
}

.footer-addition-column-link:hover, .footer-addition-column-link:focus {
    color: #FFFFFF;
    opacity: 60%;
}

@media (min-width: 768px) 
{
    .footer-addition-wrapper {
        min-height: 280px;
    }

    .footer-addition-elements-position {
        display: flex;
        justify-content: space-between;
        align-items: top;
        height: 100%;
        width: auto;
        padding-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 0;
    }

    .footer-mobile-menu {
        padding-top: 0;
    }
}
