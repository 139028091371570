h4 {
    font-family: 'Arimo';
    font-size: 22px;
    font-weight: 500;
    color: #2A2928;
}

h3 {
    font-family: 'Arimo';
    font-size: 24px;
    font-weight: 500;
    color: #2A2928;
}

h5 {
    font-family: 'Arimo';
    font-size: 18px;
    font-weight: 500;
    color: #2A2928;
}

h2 {
    font-family: 'Arimo';
    font-size: 26px;
    font-weight: 500;
    color: #2A2928;
}

h1 {
    font-family: 'Arimo';
    font-size: 36px;
    font-weight: 500;
    color: #2A2928;
}

p {
    font-family: 'Raleway';
    font-weight: 400;
    size: 14px;
    color: #666666;
}

.color-paragraph {
    font-family: 'Raleway';
    font-weight: 400;
    size: 18px;
    color: #F34804;
}

.main-color {
    color: #F34804;
}


@media (min-width: 768px) 
{
    h1 {
        font-size: 56px;
    }

    h2 {
        font-size: 40px;
    }

    h5 {
        font-size: 18px;
    }
    h4 {
        font-size: 24px;
    }
    h3 {
        font-size: 32px;
    }
    p {
        size: 18px;
    }
}

.main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.styled-button {
    color: white;
    background-color: #F34804;
    border-color: #F34804;
}

.styled-button:hover {
    background-color: #F5E7D7;
    color: #F34804;
    border-color: #F34804;
    border-radius: 5px;
}

.styled-button:active, .styled-button-light:active {
    background-color: #0C5ABA !important;
    border-color: #F5E7D7 !important;
    border-radius: 5px !important;
}

.styled-button-light {
    color: #F34804;
    background-color: #F5E7D7;
    border-color: #F34804;
}

.styled-button-light:hover {
    background-color: #F34804;
    color: #F5E7D7;
    border-color: #F5E7D7;
    border-radius: 5px;
}


.page-title {
    margin-bottom: 30px;
    margin-top: 30px;
    size: 22;
    text-align: center;
    color: #F34804;
}

.page-subtitle {
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: left;
    font-weight: 500;
    color: #F34804;    
}

.centred-element {
    align-items: center;
    text-align: center;
}

.styled-checkbox .form-check-input:checked {
    background-color: #F34804;
    border-color: #F34804;
}

.styled-text {
    color: #F34804;
    margin-left: 10%;
    margin-right: 10%;
}

.styled-text h1 {
    text-align: center;
    font-size: 60px; 
    margin-top: 20px;
    margin-bottom: 20px;
}

.styled-text h2 {
    text-align: left;
    font-size: 30px; 
    font-weight: 500; 
    margin-top: 20px;
    margin-bottom: 20px;
    color: #F34804;
}

.styled-radio .form-check-input:checked {
    background-color: #F34804;
    border-color: #F34804;
}
