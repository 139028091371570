.time-proposal-container {
    margin: 20px 0;
    padding: 10px;
    border-radius: 8px;
    background-color: #f5f5f5;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.time-proposal-inputs {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.time-proposal-input {
    flex-grow: 1;
}