.blog-item-wrapper{
    cursor: pointer;
    min-height: 480px;
}

.blog-item-image {
    object-fit: cover;
}


@media (min-width: 768px) 
{    
    .blog-item-image {
        max-width: 550px;
        max-height: 280px;
        min-height: 280px;
        object-position: 100% 35%;
    }
}