.news-page-banner-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.news-page-banner-text {
    text-align: justify;
}

@media (min-width: 768px) 
{
    .news-page-banner-wrapper {
        text-align: center;
    }
}
