.custom-select {
    padding: 12px 16px 12px 16px;
    line-height: 1.5;
    color:#666666;
    font-family: 'Jost';
    font-weight: 400;
    font-size: 16px;
    background-color: #fff;
    border: 1px solid #ADACB2;
    width: 100%;
    border-radius: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23495057' d='M8 13.1l-8-8 2.1-2.2 5.9 5.9 5.9-5.9 2.1 2.2z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.75rem center; 
    background-size: 1rem 1rem;
  }
  
  .custom-select:focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 72, 4, 0.25);
  }
  
  .custom-select:disabled {
    background-color: #e9ecef;
  }
