.styled-chart-wrapper {
    background: #F5F5F580;
    border-radius: 8px;
}

.pie-chart-container {
    width: 100%;
    max-width: 400px; 
    height: auto;
}


.chart-legend .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.chart-legend .legend-color {
    width: 20px;
    height: 20px;
    display: block;
    margin-right: 10px;
    border-radius: 50%; 
}

.chart-legend .legend-title {
    font-size: 14px;
}

.first-row {
    margin-top: 20px;
}