.unlock-the-power-wrapper {
    max-width: 90%;
    border-radius: 24px 24px 120px 24px;
    background: var(--Warning-BG, rgba(255, 195, 0, 0.12));
    padding-bottom: 30px;
}

.unlock-the-power-image {
    width: 100%;
    height: auto; 
    object-fit: cover;
    object-position: center; 
}

.unlock-the-power-mobile-no-display {
    display: none;
}

@media (min-width: 768px) 
{
    .unlock-the-power-mobile-no-display {
        display: flex;
    }

    .unlock-the-power-text {
        padding-left: 30px;
        padding-top: 30px;
        padding-right: 50px;
    }
}