.circled-button-wrapper {
    display: flex; 
    align-items: center; 
    background-color: rgba(243, 72, 4, 0.12); 
    padding: 0 0 0 0; 
    border-radius: 80px; 
    color: #2A2928;
    font-family: 'Jost', sans-serif; 
    font-size: 16px;
    font-weight: 500;
    text-align: left; 
    justify-content: space-between; 
    width: 100%;
    cursor: pointer;
}

.circle-arrow-wrapper {
    background-color: #F34804;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    font-size: 36px;
}

.circled-button-small-wrapper {
    display: flex; 
    align-items: center; 
    background-color: rgba(243, 72, 4, 0.12); 
    padding: 0 0 0 0; 
    border-radius: 80px; 
    color: #2A2928;
    font-family: 'Jost', sans-serif; 
    font-size: 16px;
    font-weight: 500;
    text-align: left; 
    max-width: 84px;
    justify-content: space-between; 
    width: 100%;
}