.image-with-titles-collage-wrapper {
    display: flex;  
    flex-direction: row; 
    height: 100%;
    align-items: center;
}

.image-with-titles-collage-image {
    height: 100%; 
    width: auto;  
    object-fit: scale-down; 
}

.image-with-titles-collage-title {
    display: flex;
    align-items: center; 
    justify-content: center;
    flex-direction: column; 
    height: 100%;
    color: #33C38F;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    opacity: 0.24;
    font-family: 'Arimo';
    font-size: 32px;
    font-weight: 500;
    text-align: center;
}

.image-with-titles-collage-title-first {
    margin-bottom: 20px;
}

.image-with-titles-collage-title-second {
    margin-top: 20px;
}

@media (min-width: 768px) 
{
    .image-with-titles-collage-title div {
        transform: rotate(90deg);
        transform-origin: center;
        white-space: nowrap;
        text-align: start;
    }
    
    .image-with-titles-collage-title-first {
        transform: translateY(-100px);
        margin-bottom: 0px;
    }
    
    .image-with-titles-collage-title-second {
        transform: translateY(100px);
        margin-top: 0px;
    }
}