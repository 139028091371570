.custom-styled-action-button-wrapper {
    padding: 8px;
    gap: 8px;
    border-radius: 8px;
    display: flex;
    box-shadow: -2px -2px 2px 0px #B8CCE0, 
        -1px -1px 0px 0px #FFFFFF, 
        -2px -2px 2px 0px #B8CCE0 inset, 
        -1px -1px 0px 0px #FFFFFF inset;
}

.custom-styled-action-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    transition: background-color 0.2s;
    box-shadow: -8px -8px 16px 0px #FFFFFF,
                8px 8px 16px 0px #C9D9E8;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    width: 100%; 
    height: 100%;

  }
  
  .custom-styled-action-button.primary {
    background-color: white;
    color: #2A2928;
  }
  
  .custom-styled-action-button.primary:hover {
    background-color: #F34804;
    color: #FFFFFF;
  }
  
  .custom-styled-action-button.secondary {
    background-color: #6c757d;
    color: white;
  }
  
  .custom-styled-action-button.secondary:hover {
    background-color: #545b62;
  }
  
  .custom-styled-action-button.danger {
    background-color: #dc3545;
    color: white;
  }
  
  .custom-styled-action-button.danger:hover {
    background-color: #c82333;
  }
  
  .custom-styled-action-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

