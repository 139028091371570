.rehumanizing-network-styled-text {
    text-align: left;
}

.rehumanizing-network-image {
    border-radius: 50%;
    height: 200px;
    width: auto;
    object-fit: scale-down;
}

.rehumanizing-network-title {
    display: flex;
    justify-content: center;
}

@media (min-width: 768px) 
{
    .rehumanizing-network-styled-text {
        max-width: 80%;
        text-align: center;
    }
}