.search-light-container {
    border: 1px solid #ADACB2;
    border-radius: 8px;
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    padding-right: 0;
}

.tiny-search-input {
    border: none;
    width: 100%;
    text-align: left;
    font-family: Jost;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    background-color: transparent;
    outline: none;
    padding-right: 0;
    padding-left: 2px;
}

.tiny-search-input:focus {
    background-color: transparent;
    border: none;
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.tiny-search-icon-column {
    flex: 0 0 30px;
    max-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
}

.tiny-search-input-column {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

@media (min-width: 768px) 
{
    .tiny-search-input {
        font-size: 14px;
    }

    .tiny-search-icon-column {
        flex: 0 0 50px;
        max-width: 50px;
        padding-left: 10px;
    }
}