.why-us-styled-text {
    text-align: justify;
}

@media (min-width: 768px) 
{
    .why-us-styled-text {
        text-align: center;
    }
}
