.google-button-wrapper{
    padding: 10px 16px 10px 16px;
    gap: 12px;
    border-radius: 8px;
    border: 1px 1px 1px 1px;
    border: 1px solid #ADACB2;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: Raleway;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    text-decoration: none; 
    color: #2A2928;
}