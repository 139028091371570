.testimonials-wrapper {
    max-width: 80%;
}

.testimonials-image {
    max-width: 100%;
    height: auto;
}

.testimonials-rating-image {
    max-width: 200px;
}

@media (min-width: 768px) 
{
    .testimonials-rating-image {
        max-width: 250px;
    }
}