.lead-generation-types-styled-text {
    text-align: left;
}

.lead-generation-types-title-image {
    width: auto;
    height: 300px;
    object-fit: cover;
    margin-bottom: 30px;
}

.lead-generation-types-title {
    display: flex;
    justify-content: center;
}

@media (min-width: 768px) 
{
    .lead-generation-types-styled-text {
        max-width: 80%;
        text-align: center;
    }

    .lead-generation-types-title-image {
        height: 400px;
    }
}