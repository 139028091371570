.image-collage-column-wrapper {
    height: 240px;
}

.image-collage-yellow-rectangle {
    background-color: #FFF8E0;
    height: 0;
    width: 90%;
    border-radius: 16px 16px 16px 40px;
}

.image-collage-green-rectangle {
    background-color: #E7F8F2;
    height: 0;
    width: 90%;
    border-radius: 16px 16px 16px 40px;
}

.first-collage-image {
    object-fit: cover;
    max-height: 200px;
    border-radius: 96px 24px 24px 24px;
    margin-top: 20px;
}

.second-collage-image {
    border-radius: 24px 24px 96px 24px;
    object-fit: cover;
    object-position: right;
    height: 200px;
    width: 100%;
}


@media (min-width: 768px) 
{
    .image-collage-wrapper {
        height: 560px;
    }    

    .image-collage-green-rectangle {
        height: 100px;
        margin-top: 20px;
        margin-left: 10px;
    }

    .image-collage-yellow-rectangle {
        margin-left: 10px;
        height: 100px;
    }
    
    .first-collage-image {
        max-height: 400px;
        margin-bottom: 20px;
    }
    
    .second-collage-image {
        height: 400px;
        margin-top: 20px;
    }
}