.table-hover tbody tr:hover {
    background-color: #F37855; 
    color: white !important;
}

.introductors-title-greyed {
    color: #666666;
    font-weight: 500;
}

.no-one-available-text {
    text-align: left;
}

@media (min-width: 768px) 
{
    .no-one-available-text {
        text-align: center;
        padding-left: 20%;
        padding-right: 20%;
    }
}